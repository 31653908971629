import React from 'react';
import loader from '../assets/img/loader.gif';
import video1 from '../assets/img/desktop.mp4';
import video2 from '../assets/img/mobile.mp4';
const API_URL = 'https://greenfield.emlsdr.ru/backend/api';

export default class Section extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      faq: 0,
      agreement: 'agreement1.pdf',
      confidential: 'confidential1.pdf',
      pravila: 'rules.pdf',
      loading: false,
      winner: [],
      data: {
        winner: {
          email: '',
          emailFocus: false,
        },
        feedback: {
          email: '',
          emailFocus: false,
          name: '',
          nameFocus: false,
          topic: null,
          topicFocus: false,
          message: '',
          messageFocus: false,
          agree: false,
          captch: '',
          captchFocus: false,
          agree1: false,
        },
      },
      errors: {
        feedback: {
          email: false,
          name: false,
          topic: false,
          message: false,
          nameText: 'Введено некорректное имя',
          emailText: 'Введен некорректный e-mail',
          topicText: 'Выберите тему сообщения',
          messageText: 'Сообщение должно быть больше 5 символов',
          agree: false,
          agreeText: 'Необходимо ваше согласие',
          captch: false,
          captchText: 'Подтвердите, что Вы не робот!',
        },
      },
      topicInput: 'Тема',
      topicDropdown: false,
    };
  }
  componentDidMount() {
    const video = document.createElement('video');
    video.autoplay = true;
    video.loop = true;
    video.muted = true; // fixes autoplay in chrome
    video.setAttribute('playsinline', 'true'); // fixes autoplay in webkit (ie. mobile safari)
    video.setAttribute('muted', 'true');
    const source = document.createElement('source');
    if (window.innerWidth > 991) {
      source.src = video1;
    } else {
      source.src = video2;
    }
    source.type = 'video/mp4';
    video.appendChild(source);

    this.videoContainer.appendChild(video);
    this.getWinner();
  }
  getWinner = async () => {
    await fetch(API_URL + '/getWinners', {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log(response);
        if (response !== null) {
          if (response.result === true || response === null) {
            this.setState({
              winner: response.data.rows,
            });
          } else {
            if (response.result !== null) {
              // console.log('Призов нет');
            }
          }
        }
      });
  };
  searchWinner = async (email) => {
    const formData = new FormData();
    formData.append('email', email);
    await fetch(API_URL + '/searchWinner', {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response.result === true) {
          this.setState({
            winner: response.data.rows,
          });
        } else {
        }
      });
  };
  renderError = (form, field) => {
    switch (form) {
      case 'feedback':
        switch (field) {
          case 'name':
            if (this.state.errors.feedback.name === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.feedback.name = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.feedback.nameText}
                </div>
              );
            }
            break;
          case 'email':
            if (this.state.errors.feedback.email === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.feedback.email = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.feedback.emailText}
                </div>
              );
            }
            break;
          case 'topic':
            if (this.state.errors.feedback.topic === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.feedback.topic = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.feedback.topicText}
                </div>
              );
            }
            break;
          case 'message':
            if (this.state.errors.feedback.message === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.feedback.message = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.feedback.messageText}
                </div>
              );
            }
            break;
          case 'agree':
            if (this.state.errors.feedback.agree === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.feedback.agree = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.feedback.agreeText}
                </div>
              );
            }
            break;
          case 'captch':
            if (this.state.errors.feedback.captch === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.feedback.captch = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.feedback.captchText}
                </div>
              );
            }
            break;
        }
        break;
    }
  };
  modalClose = () => {
    document.body.classList.remove('overflow-disabled');

    let data = {
      winner: {
        email: '',
        emailFocus: false,
      },
      feedback: {
        email: '',
        emailFocus: false,
        name: '',
        nameFocus: false,
        topic: null,
        topicFocus: false,
        message: '',
        messageFocus: false,
        agree: false,
        captch: '',
        captchFocus: false,
        agree1: false,
      },
    };
    let error = {
      feedback: {
        email: false,
        name: false,
        topic: false,
        message: false,
        nameText: 'Введено некорректное имя',
        emailText: 'Введен некорректный e-mail',
        topicText: 'Выберите тему сообщения',
        messageText: 'Сообщение должно быть больше 5 символов',
        agree: false,
        agreeText: 'Необходимо ваше согласие',
        captch: false,
        captchText: 'Подтвердите, что Вы не робот!',
      },
    };
    this.setState({ errors: error, data: data, topicInput: 'Тема' });

    // this.setState({
    //   // loading: false,
    //   data: {
    //     feedback: {
    //       email: '',
    //       emailFocus: false,
    //       name: '',
    //       nameFocus: false,
    //       topic: null,
    //       topicFocus: false,
    //       message: '',
    //       messageFocus: false,
    //       agree: false,
    //       captch: '',
    //       captchFocus: false,
    //       agree1: false,
    //     },
    //   },
    //   errors: {
    //     feedback: {
    //       email: false,
    //       name: false,
    //       topic: false,
    //       message: false,
    //       nameText: 'Введено некорректное имя',
    //       emailText: 'Введен некорректный e-mail',
    //       topicText: 'Выберите тему сообщения',
    //       messageText: 'Сообщение должно быть больше 5 символов',
    //       agree: false,
    //       agreeText: 'Необходимо ваше согласие',
    //       captch: false,
    //       captchText: 'Подтвердите, что Вы не робот!',
    //     },
    //   },
    //   topicInput: 'Тема',
    //   topicDropdown: false,
    // });
  };
  submitFeedback = async () => {
    let errors = this.state.errors;
    let submit_enable = true;
    if (this.state.data.feedback.email === '') {
      errors.feedback.email = true;
      submit_enable = false;
    } else {
      let re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(this.state.data.feedback.email)) {
        errors.feedback.email = true;
        submit_enable = false;
      } else {
        errors.feedback.email = false;
      }
    }

    if (this.state.data.feedback.name.length < 2) {
      errors.feedback.name = true;
      submit_enable = false;
    } else {
      errors.feedback.name = false;
    }

    if (this.state.data.feedback.topic === null || this.state.data.feedback.topic === '') {
      errors.feedback.topic = true;
      submit_enable = false;
    } else {
      errors.feedback.topic = false;
    }

    if (this.state.data.feedback.message.length < 5) {
      errors.feedback.message = true;
      submit_enable = false;
    } else {
      errors.feedback.message = false;
    }

    if (!this.state.data.feedback.agree) {
      errors.feedback.agree = true;
      submit_enable = false;
    } else {
      errors.feedback.agree = false;
    }

    this.setState({
      errors: errors,
    });
    console.log('response1');
    if (submit_enable === true) {
      this.setState({
        loading: true,
      });
      this.btnAnalitic('Zadat_Vopros');

      const formData = new FormData();
      formData.append('email', this.state.data.feedback.email);
      formData.append('name', this.state.data.feedback.name);
      formData.append('subject_id', this.state.data.feedback.topic);
      formData.append('text', this.state.data.feedback.message);
      formData.append('accept1', 1);

      await fetch(API_URL + '/sendFeedback', {
        method: 'POST',
        body: formData,
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.result === true) {
            console.log(response);
            this.modalClose();

            this.props.info4Show();
            console.log(response);
          } else {
            this.setState({
              errors: errors,
            });
          }

          this.setState({
            loading: false,
          });
        });
    }
  };
  selectTopic = (topic, title) => {
    let data = this.state.data;
    data.feedback.topic = topic;
    let errors = this.state.errors;
    errors.feedback.topic = false;
    this.setState({
      data: data,
      errors: errors,
      topicDropdown: false,
      topicInput: title,
    });
  };
  toggleTopicDropdown = () => {
    let errors = this.state.errors;
    errors.feedback.topic = false;
    this.setState({
      topicDropdown: !this.state.topicDropdown,
      errors: errors,
    });
  };
  toggleFaq = (numb) => {
    if (this.state.faq === numb) {
      this.setState({
        faq: 0,
      });
    } else {
      this.setState({
        faq: numb,
      });
    }
  };
  hov = () => {
    console.log('hover');
  };
  btnAnalitic = (pixId) => {
    if (typeof window.__GetI === 'undefined') {
      window.__GetI = [];
    }
    (function () {
      console.log(pixId);
      var p = {
        type: 'VIEW',
        /* config START */
        site_id: '8835',
        product_id: '',
        product_price: '',
        category_id: '',
        pixel_id: pixId,
        /* config END */
      };
      window.__GetI.push(p);
      var domain =
        typeof window.__GetI_domain == 'undefined' ? 'px.adhigh.net' : window.__GetI_domain;
      var src =
        ('https:' == document.location.protocol ? 'https://' : 'http://') + domain + '/t.js';
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = src;
      var s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(script, s);
    })();
  };
  btnUchastvovat = (clName, btnTitle) => {
    if (
      localStorage.getItem('auth_key') &&
      localStorage.getItem('auth_key') !== null &&
      localStorage.getItem('auth_key') !== undefined
    ) {
      return (
        <button
          id="apm-scan-qr2"
          className={'btn upload-check custom-main-button btn-' + clName}
          onClick={() => {
            this.btnAnalitic('Uchastie');
            window.generalPixel.postClick('a1');
            document.getElementById('apm-scan-qr').click();
            // this.props.info8Show();
            console.log('sdfsdf');
            window._tmr.push({ type: 'reachGoal', id: 3259179, goal: 'participate' });
            // window.dataLayer.push({
            //   event: 'gaEvent',
            //   eventCategory: 'internal',
            //   eventAction: 'click',
            //   eventLabel: 'load_cheque_main',
            // });
          }}>
          {btnTitle}
        </button>
      );
    } else {
      return (
        <button
          className={'btn upload-check btn-' + clName}
          onClick={() => {
            this.btnAnalitic('Uchastie');
            window.generalPixel.postClick('a1');
            window._tmr.push({ type: 'reachGoal', id: 3259179, goal: 'participate' });
            this.props.loginShow();
            console.log('sdfsdf2');
            // window.dataLayer.push({
            //   event: 'gaEvent',
            //   eventCategory: 'internal',
            //   eventAction: 'click',
            //   eventLabel: 'load_cheque_main',
            // });
          }}>
          {btnTitle}
        </button>
      );
    }
  };
  btnUploadCheck = (clName, btnTitle) => {
    if (
      localStorage.getItem('auth_key') &&
      localStorage.getItem('auth_key') !== null &&
      localStorage.getItem('auth_key') !== undefined
    ) {
      return (
        <button
          id="apm-scan-qr2"
          className={'btn upload-check custom-main-button btn-' + clName}
          onClick={() => {
            window.dataLayer.push({
              event: 'gaEvent',
              eventCategory: 'internal',
              eventAction: 'click',
              eventLabel: 'join',
            });
            window._tmr.push({ type: 'reachGoal', id: 3259179, goal: 'participate' });
            this.btnAnalitic('Reg_Chek');
            window.generalPixel.postClick('a2');
            document.getElementById('apm-scan-qr').click();
            // this.props.info8Show();
          }}>
          {btnTitle}
        </button>
      );
    } else {
      return (
        <button
          className={'btn upload-check btn-' + clName}
          onClick={() => {
            window.dataLayer.push({
              event: 'gaEvent',
              eventCategory: 'internal',
              eventAction: 'click',
              eventLabel: 'join',
            });
            window._tmr.push({ type: 'reachGoal', id: 3259179, goal: 'participate' });
            this.btnAnalitic('Reg_Chek');
            window.generalPixel.postClick('a2');
            this.props.loginShow();
          }}>
          {btnTitle}
        </button>
      );
    }
  };
  btnChat = (clName) => {
    if (
      localStorage.getItem('auth_key') &&
      localStorage.getItem('auth_key') !== null &&
      localStorage.getItem('auth_key') !== undefined
    ) {
      return (
        <a
          className={'btn upload-check btn-' + clName}
          href={
            'https://api.whatsapp.com/send?phone=79957852511&text=%D0%9F%D1%80%D0%B8%D0%B2%D0%B5%D1%82' +
            localStorage.getItem('auth_key')
          }
          onClick={() => {}}>
          Перейти в чат-бот
        </a>
      );
    } else {
      return (
        <a
          className={'btn upload-check btn-' + clName}
          href="https://api.whatsapp.com/send?phone=79957852511&text=%D0%9F%D1%80%D0%B8%D0%B2%D0%B5%D1%82"
          onClick={() => {}}>
          Перейти в чат-бот
        </a>
      );
    }
  };
  btnPrize = (name, price, id) => {
    // if (
    //   localStorage.getItem('auth_key') &&
    //   localStorage.getItem('auth_key') !== null &&
    //   localStorage.getItem('auth_key') !== undefined
    // ) {
    //   return (
    //     <a
    //       className={'btn btn-prize btn-' + id}
    //       href={
    //         'https://api.whatsapp.com/send?phone=79957852511&text=%D0%9F%D1%80%D0%B8%D0%B2%D0%B5%D1%82' +
    //         localStorage.getItem('auth_key')
    //       }
    //       onClick={() => {}}>
    //       Перейти в чат-бот
    //     </a>
    //   );
    // } else {
    // }
    return (
      <button
        className={'btn btn-prize btn-prize-' + id}
        onClick={() => {
          this.props.prizeShow(name, price, id);
          // this.props.info8Show();
        }}>
        <span className="m-hidden">ОБМЕНЯТЬ БАЛЛЫ НА ПОДАРОК</span>
        <span className="d-hidden">Заказать</span>
      </button>
    );
  };

  hoverChatAdd = (id) => {
    setTimeout(() => {
      document.querySelector('.box-chat').classList.add('hover');
    }, 500);
  };
  hoverChatRemove = (id) => {
    setTimeout(() => {
      document.querySelector('.box-chat').classList.remove('hover');
    }, 500);
  };
  renderPrizes = () => {
    // console.log(this.props.data.state.prizes);
    if (this.props.data.state.prizes.length > 0) {
      return this.props.data.state.prizes?.map(
        (pr, index) =>
          pr.showInHome && (
            <div key={index} className={'section-prize section-prize-' + pr.id}>
              <div className={'section-prize-text section-prize-' + pr.id + '-text'}>{pr.name}</div>
              {pr.old_price !== null && (
                <div className={'section-prize-benefit section-prize-' + pr.id + '-benefit'}>
                  {/* <span>Выгода</span> */}
                  <span>{pr.old_price - pr.price}</span>
                  {/* <span>баллов</span> */}
                </div>
              )}
              <div className={'section-prize-price section-prize-' + pr.id + '-price'}>
                {pr.old_price !== null && <span className="old_price">{pr.old_price}</span>}
                {pr.price}
                <span>баллов</span>
              </div>
              <div className={'section-prize-icon section-prize-' + pr.id + '-icon'}>
                <span></span>
              </div>
              <div className={'section-prize-btn section-prize-' + pr.id + '-btn'}>
                {this.btnPrize(pr.name, pr.price, pr.id)}
              </div>
            </div>
          ),
      );
    } else {
      return <div className="table__row d-flex align-items-start"></div>;
    }
  };
  render() {
    return (
      <div id="fullpage-wrapper">
        <div className="section section1 square fp-auto-height-responsive" id="main">
          <div
            className="section1-bg"
            ref={(ref) => {
              this.videoContainer = ref;
            }}>
            {/* <video
              width="100%"
              height="100%"
              className="m-hidden"
              autoPlay
              muted="true"
              muted
              playsInline
              loop>
              <source src={video1} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
            </video>
            <video
              width="100%"
              height="100%"
              className="d-hidden"
              autoPlay
              muted
              playsInline
              muted="true"
              loop>
              <source src={video2} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
            </video> */}
          </div>
          <div className="container">
            <div className="slogan">GREENFIELD CLUB</div>
            {this.btnUchastvovat('1 btn-arrow-1', 'Участвовать')}
            <div className="box-chat hover2">
              <a
                href="https://t.me/greenfieldworld_bot"
                target="_BLANK"
                onMouseOver={() => {
                  this.hoverChatAdd(1);
                }}
                onMouseOut={() => {
                  this.hoverChatRemove(1);
                }}
                onClick={() => {
                  window.dataLayer.push({
                    event: 'gaEvent',
                    eventCategory: 'internal',
                    eventAction: 'click',
                    eventLabel: 'bot_tg',
                  });
                }}>
                <span className="box-chat__text">ПЕРЕЙТИ В ЧАТ-БОТ</span>
                <span className="ico ico-telegramm"></span>
              </a>
              {/* <a
                href="https://api.whatsapp.com/send?phone=79250996576&text=%D0%9D%D0%B0%D1%87%D0%B0%D1%82%D1%8C"
                target="_BLANK"
                onMouseOver={() => {
                  this.hoverChatAdd(2);
                }}
                onMouseOut={() => {
                  this.hoverChatRemove(2);
                }}
                onClick={() => {
                  window.dataLayer.push({
                    event: 'gaEvent',
                    eventCategory: 'internal',
                    eventAction: 'click',
                    eventLabel: 'bot_wa',
                  });
                }}>
                <span className="box-chat__text">ПЕРЕЙТИ В ЧАТ-БОТ</span>
                <span className="ico ico-whatsap"></span>
              </a> */}
              <a
                href="https://vk.me/greenfieldworld"
                target="_BLANK"
                onMouseOver={() => {
                  this.hoverChatAdd(3);
                }}
                onMouseOut={() => {
                  this.hoverChatRemove(3);
                }}
                onClick={() => {
                  window.dataLayer.push({
                    event: 'gaEvent',
                    eventCategory: 'internal',
                    eventAction: 'click',
                    eventLabel: 'bot_vk',
                  });
                }}>
                <span className="box-chat__text">ПЕРЕЙТИ В ЧАТ-БОТ</span>
                <span className="ico ico-vk"></span>
              </a>
            </div>
            <div className="section1-content ">
              <div className="slogan-min">ПРИВИЛЕГИИ ВЫБОРА GREENFIELD</div>
              <p>
                Присоединяйтесь к&nbsp;программе лояльности Greenfield Club, регистрируйте чеки
                на&nbsp;greenfield-club.ru и&nbsp;выбирайте специальные призы от&nbsp;любимого
                бренда.
              </p>
              <h1 className="slogan-minimin">
                Чем больше покупок чая Greenfield, тем больше подарков!
              </h1>
              {this.btnUploadCheck('1', 'Зарегистрировать чек')}
              <div className="d-col-12 d-flex d-f-row m-f-column text-center"></div>
            </div>
          </div>
        </div>
        <div
          name="participation"
          className="section square section-template2 section2 section2-2 text-center"
          id="">
          <div id="participation" className="anchor"></div>
          <div className="container">
            <div className="steps">
              <div className="step step-1">
                <div className="step-img step-img-1">
                  <div className="step-number">1</div>
                  <div className="step-img-box">
                    <div className="step-img-box-img step-img-box-1"></div>
                    <div className="step-img-box-img step-img-box-2"></div>
                    <div className="step-img-box-img step-img-box-3"></div>
                    <div className="step-img-box-img step-img-box-4"></div>
                  </div>
                </div>
                <div className="step-text">Покупайте чай Greenfield в&nbsp;любом магазине</div>
              </div>
              <div className="step step-2">
                <div className="step-img step-img-2">
                  <div className="step-number">2</div>
                  <div className="step-img-box">
                    <div className="step-img-box-img step-img-box-1"></div>
                    <div className="step-img-box-img step-img-box-2"></div>
                    <div className="step-img-box-img step-img-box-3"></div>
                  </div>
                </div>
                <div className="step-text">Регистрируйте чеки с&nbsp;чаем Greenfield</div>
              </div>
              <div className="step step-3">
                <div className="step-img step-img-3">
                  <div className="step-number">3</div>
                  <div className="step-img-box">
                    <div className="step-img-box-img step-img-box-1"></div>
                    <div className="step-img-box-img step-img-box-2"></div>
                    <div className="step-img-box-img step-img-box-3"></div>
                    <div className="step-img-box-img step-img-box-4"></div>
                    <div className="step-img-box-img step-img-box-5"></div>
                    <div className="step-img-box-img step-img-box-6"></div>
                  </div>
                </div>
                <div className="step-text">Копите баллы</div>
              </div>
              <div className="step step-4">
                <div className="step-img step-img-4">
                  <div className="step-number">4</div>
                  <div className="step-img-box">
                    <div className="step-img-box-img step-img-box-1"></div>
                    <div className="step-img-box-img step-img-box-2"></div>
                    <div className="step-img-box-img step-img-box-3"></div>
                  </div>
                </div>
                <div className="step-text">Выбирайте гарантированные подарки</div>
              </div>
              <div className="text-center">
                <a href={this.props.link.pravila + '.pdf'} target="_BLANK" className="step-pravila">
                  ПРАВИЛА АКЦИИ
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          name="prizes"
          className="section section-template2 section-prizes section3 section2-2 text-center"
          id="">
          <div id="prize" className="anchor"></div>
          <h2 className="section-title">
            <span>
              ГАРАНТИРОВАННЫЕ <br />
              ПОДАРКИ
            </span>
          </h2>
          <div className="marquee1">
            <div>
              {this.props.prizes?.map((pr, index) => (
                <div key={index}>{pr.name}</div>
              ))}
            </div>
            <div>
              {this.props.prizes?.map((pr, index) => (
                <div key={index}>{pr.name}</div>
              ))}
            </div>
          </div>
          <div className="max-container d-flex d-f-column pos-rel">
            <div className="section-content">
              <div className="prize-info">КАЖДЫЕ 10 РУБЛЕЙ = 1 БАЛЛ</div>
              <div className="section-prizes">
                {this.renderPrizes()}
                {/* <div className={'section-prize section-prize-10'}>
                  <div className={'section-prize-text section-prize-10-text'}>asdasd</div>
                  <div className={'section-prize-price section-prize-10-price'}>
                    asdasd
                    <span>баллов</span>
                  </div>
                  <div className={'section-prize-icon section-prize-10-icon'}>
                    <span></span>
                  </div>
                  <div className={'section-prize-btn section-prize-10-btn'}>
                    {this.btnPrize('asdasdasd', 232, 10)}
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="max-container d-flex d-f-column pos-rel text-center">
            <div className="container">
              <h2 className="section-title">
                <span>ЕЖЕМЕСЯЧНЫЙ РОЗЫГРЫШ</span>
              </h2>
            </div>
            <div className="marquee">
              <div>
                Сертификат М.Видео на 10 000  рублей. Розыгрыш среди участников, которые
                зарегистрировали не менее 3 чеков за календарный месяц
              </div>
              <div>
                Сертификат М.Видео на 10 000  рублей. Розыгрыш среди участников, которые
                зарегистрировали не менее 3 чеков за календарный месяц
              </div>
            </div>
            <div className="prize-main"></div>
          </div>
        </div>
        <div className="section section-template2 section6 section-question-answer " id="">
          <div id="question-answer" className="anchor"></div>
          <div className="container">
            <h2 className="section-title">
              <span>Победители</span>
            </h2>
            <div className="section-content">
              <div className="section-winner">
                <div className="section-winner-input">
                  <div
                    className={[
                      'form__field',
                      this.state.errors.feedback.email === true ? 'has-error' : '',
                    ].join(' ')}>
                    <input
                      placeholder="Поиск по E-MAIL"
                      ref={(email) => {
                        this.emailInput = email;
                      }}
                      type="text"
                      name="email"
                      onFocus={() => {
                        let errors = this.state.errors;
                        let data = this.state.data;
                        data.winner.emailFocus = true;
                        errors.winner.email = false;
                        this.setState({ errors: errors, data: data });
                      }}
                      autoComplete="off"
                      value={this.state.data.winner.email}
                      onChange={(email) => {
                        let data = this.state.data;
                        data.winner.email = email.target.value;
                        this.setState({ data: data });
                      }}
                      onBlur={() => {
                        let data = this.state.data;
                        data.winner.emailFocus = false;
                        this.setState({ data: data });
                      }}
                    />
                  </div>
                  <button
                    className="ico ico-seach"
                    onClick={() => this.searchWinner(this.state.data.winner.email)}></button>
                </div>
                {this.state.winner?.length > 0 ? (
                  <div>
                    <div className="section-winner-item section-winner-item-header">
                      <div>Имя</div>
                      <div>E-MAIL</div>
                      <div>ПРИЗ</div>
                    </div>
                    {this.state.winner?.map((list, index) => (
                      <div className="section-winner-item" key={'winner-item-' + index}>
                        <div>
                          <span>Имя</span>
                          {list.name}
                        </div>
                        <div>
                          <span>E-MAIL</span>
                          {list.email}
                        </div>
                        <div>
                          <span>ПРИЗ</span>
                          {list.prize}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="section-winner-item">Нет данных</div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="section section-template2 section5 section-question-answer " id="">
          <div id="question-answer" className="anchor"></div>
          <div className="container">
            <h2 className="section-title">
              <span>вопрос · ответ</span>
            </h2>
            <div className="section-content">
              <div className="section-content-left">
                <div className="faq__list">
                  <div
                    onClick={() => {
                      this.toggleFaq(1);
                    }}
                    className={'faq__item ' + (this.state.faq === 1 ? 'active' : '')}>
                    <div className="faq__item-header color-1">
                      Когда проводится программа лояльности Greenfield Club?
                    </div>
                    <div className={'faq__item-answer fadeIn animated'}>
                      <div className="faq__item-text">
                        <b>
                          Период покупки Greenfield, регистрации чека и&nbsp;обмена баллов
                          на&nbsp;подарки
                        </b>
                        &nbsp;с&nbsp;16&nbsp;февраля 2024&nbsp;г.&nbsp;00:00:00 (время московское)
                        по&nbsp;24&nbsp;декабря 2024&nbsp;г.&nbsp;23:59:59 (время московское)
                        включительно. <br />
                        <b>Вручение подарков</b> осуществляется в&nbsp;течение общего срока
                        проведения по&nbsp;31 декабря 2024 г.
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      this.toggleFaq(2);
                    }}
                    className={'faq__item ' + (this.state.faq === 2 ? 'active' : '')}>
                    <div className="faq__item-header color-2">Кто может принять участие?</div>
                    <div className={'faq__item-answer fadeIn animated'}>
                      <div className="faq__item-text">
                        Граждане Российской Федерации, достигшие 18&nbsp;лет, проживающие
                        в&nbsp;Российской Федерации.
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      this.toggleFaq(3);
                    }}
                    className={'faq__item ' + (this.state.faq === 3 ? 'active' : '')}>
                    <div className="faq__item-header color-3">
                      В&nbsp;каких городах и&nbsp;магазинах можно совершить покупку?
                    </div>
                    <div className={'faq__item-answer fadeIn animated'}>
                      <div className="faq__item-text">
                        В&nbsp;любом магазине на&nbsp;всей территории Российской Федерации.
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      this.toggleFaq(4);
                    }}
                    className={'faq__item ' + (this.state.faq === 4 ? 'active' : '')}>
                    <div className="faq__item-header color-4">
                      Какая продукция &laquo;Greenfield&raquo; участвует в&nbsp;программе
                      лояльности?
                    </div>
                    <div className={'faq__item-answer fadeIn animated'}>
                      <div className="faq__item-text">
                        Весь ассортимент чайной продукции &laquo;Greenfield&raquo;, приобретенный
                        в&nbsp;магазинах на&nbsp;территории Российской Федерации.
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      this.toggleFaq(5);
                    }}
                    className={'faq__item ' + (this.state.faq === 5 ? 'active' : '')}>
                    <div className="faq__item-header color-5">Как принять участие ?</div>
                    <div className={'faq__item-answer fadeIn animated'}>
                      <div className="faq__item-text">
                        <b>
                          1.Купить не&nbsp;менее одной упаковки чайной продукции под товарным знаком
                          &laquo;Greenfield&raquo;
                        </b>
                        , имеющейся в&nbsp;продаже в&nbsp;магазинах на&nbsp;территории Российской
                        Федерации.
                        <br />
                        <b>2.Сохранить чек</b> на&nbsp;покупку до&nbsp;конца срока проведения
                        программы лояльности и&nbsp;вручения подарков.
                        <br />
                        <b>3.Пройти регистрацию на&nbsp;сайте или в&nbsp;чат-боте</b>, указав свои
                        данные: фамилия, имя, отчество, Email и&nbsp;номер мобильного телефона.
                        <br />
                        <b>4.Зарегистрировать чек</b> в&nbsp;период проведения программы лояльности,
                        кликнув на&nbsp;кнопку &laquo;Зарегистрировать чек&raquo; на&nbsp;сайте или
                        в&nbsp;чат-боте.
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      this.toggleFaq(6);
                    }}
                    className={'faq__item ' + (this.state.faq === 6 ? 'active' : '')}>
                    <div className="faq__item-header color-1">Нужно&nbsp;ли мне сохранять чек?</div>
                    <div className={'faq__item-answer fadeIn animated'}>
                      <div className="faq__item-text">
                        Участник обязан сохранить оригиналы зарегистрированных чеков
                        до&nbsp;окончания проведения программы лояльности и&nbsp;вручения подарков.{' '}
                        <br />
                        <a href={this.props.link.pravila + '.pdf'} target="_BLANK">
                          Участник обязан сохранить оригиналы зарегистрированных чеков
                          до&nbsp;окончания проведения программы лояльности и&nbsp;вручения
                          подарков. <br />
                          Согласно п.&nbsp;6.6. правил акции, организатор в&nbsp;любой момент
                          проведения акции вправе запросить, а&nbsp;участник обязан предоставить
                          изображение упаковки акционного товара и&nbsp;фискальные чеки
                          на&nbsp;покупку товара.
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      this.toggleFaq(7);
                    }}
                    className={'faq__item ' + (this.state.faq === 7 ? 'active' : '')}>
                    <div className="faq__item-header color-1">Как получить подарок?</div>
                    <div className={'faq__item-answer fadeIn animated'}>
                      <div className="faq__item-text">
                        Накопить необходимое количество баллов и&nbsp;обменять в&nbsp;личном
                        кабинете накопленные баллы на&nbsp;понравившийся подарок.
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      this.toggleFaq(8);
                    }}
                    className={'faq__item ' + (this.state.faq === 8 ? 'active' : '')}>
                    <div className="faq__item-header color-3">Как копятся баллы?</div>
                    <div className={'faq__item-answer fadeIn animated'}>
                      <div className="faq__item-text">
                        За&nbsp;регистрацию чека начисляются баллы в&nbsp;соотношении: каждые
                        10&nbsp;потраченных рублей на&nbsp;покупку продукции с&nbsp;товарным знаком
                        Greenfield = 1&nbsp;балл на&nbsp;счет в&nbsp;личном кабинете. <br />
                        Начисление баллов производится в&nbsp;течение 3&nbsp;рабочих дней после
                        регистрации чека на&nbsp;сайте акции.
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      this.toggleFaq(9);
                    }}
                    className={'faq__item ' + (this.state.faq === 9 ? 'active' : '')}>
                    <div className="faq__item-header color-4">
                      Можно ли передать баллы другому человеку?
                    </div>
                    <div className={'faq__item-answer fadeIn animated'}>
                      <div className="faq__item-text">
                        Накопленные баллы не&nbsp;могут быть переданы, уступлены, подарены, проданы
                        или иным образом отчуждены третьим лицам.
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      this.toggleFaq(10);
                    }}
                    className={'faq__item ' + (this.state.faq === 10 ? 'active' : '')}>
                    <div className="faq__item-header color-5">Как зарегистрировать чек?</div>
                    <div className={'faq__item-answer fadeIn animated'}>
                      <div className="faq__item-text">
                        Для того, чтобы зарегистрировать чек, необходимо:
                        <br />
                        &bull; быть зарегистрированным и&nbsp;авторизованным на&nbsp;сайте или
                        в&nbsp;чат-боте.
                        <br />
                        &bull; нажать на&nbsp;кнопку &laquo;загрузить чек&raquo; на&nbsp;сайте или
                        &laquo;чек&raquo; в&nbsp;чат-боте.
                        <br />
                        &bull; отсканировать камерой телефона QR-код, расположенный на&nbsp;чеке.
                        <br />
                        <br />
                        Если Вы&nbsp;не&nbsp;можете отсканировать QR-код с&nbsp;чека, можно
                        зарегистрировать чек одним из&nbsp;способов: <br />
                        &bull; ввести данные с&nbsp;чека вручную, а&nbsp;именно: дата и&nbsp;время
                        чека, сумма чека, ФН, ФД, ФП&nbsp;или ФПД
                        <br />
                        &bull;&thinsp;загрузить фотографию чека (подробнее о&nbsp;том, какой должна
                        быть фотография, указано в&nbsp;
                        <a href={this.props.link.pravila + '.pdf'} target="_BLANK">
                          п.6.1.3. правил программы лояльности
                        </a>
                        . )
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      this.toggleFaq(11);
                    }}
                    className={'faq__item ' + (this.state.faq === 11 ? 'active' : '')}>
                    <div className="faq__item-header color-1">
                      Не&nbsp;получается загрузить чек&nbsp;&mdash; что делать?
                    </div>
                    <div className={'faq__item-answer fadeIn animated'}>
                      <div className="faq__item-text">
                        Обратитесь в&nbsp;службу поддержки через форму обратной связи на&nbsp;сайте.
                        Срок предоставления обратной связи может составлять до&nbsp;3&nbsp;рабочих
                        дней.
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      this.toggleFaq(12);
                    }}
                    className={'faq__item ' + (this.state.faq === 12 ? 'active' : '')}>
                    <div className="faq__item-header color-2">
                      Сколько чеков я&nbsp;могу зарегистрировать?
                    </div>
                    <div className={'faq__item-answer fadeIn animated'}>
                      <div className="faq__item-text">
                        Каждый участник может зарегистрировать в&nbsp;программе лояльности
                        неограниченное количество чеков.
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      this.toggleFaq(13);
                    }}
                    className={'faq__item ' + (this.state.faq === 13 ? 'active' : '')}>
                    <div className="faq__item-header color-3">Какие подарки можно получить?</div>
                    <div className={'faq__item-answer fadeIn animated'}>
                      <div className="faq__item-text">
                        <table className="table table-bordered ">
                          <tbody>
                            <tr>
                              <th>Наименование Подарка</th>
                              <th>Количество баллов необходимых для обмена на Подарок</th>
                            </tr>
                            <tr>
                              <td>
                                Сертификат на&nbsp;благотворительность номиналом 100&nbsp;руб.
                              </td>
                              <td>100</td>
                            </tr>
                            <tr>
                              <td>
                                45&nbsp;дней подписки на&nbsp;онлайн-кинотеатр
                                &laquo;PREMIER&raquo;. Предложение действует для новых
                                пользователей.
                                <br />
                                Активировать промокод можно до&nbsp;31.12.2024&nbsp;на
                                <a href="https://premier.one/profile/promocode" target="_BLANK">
                                  https://premier.one/profile/promocode
                                </a>{' '}
                                <br />
                                Подробные условия акции:
                                <a href="https://premier.one/info/tntp/promocode" target="_BLANK">
                                  https://premier.one/info/tntp/promocode
                                </a>
                              </td>
                              <td>100</td>
                            </tr>
                            <tr>
                              <td>Шопер (размер 49*34&nbsp;см)</td>
                              <td>300</td>
                            </tr>
                            <tr>
                              <td>
                                Набор: Шопер + Брендированная футболка со&nbsp;стикерами (размер
                                единый)
                              </td>
                              <td>700</td>
                            </tr>
                            <tr>
                              <td>Коллекция чая GREENFIELD&nbsp;в пирамидках (6&nbsp;сортов)</td>
                              <td>400</td>
                            </tr>
                            <tr>
                              <td>Пляжная сумка</td>
                              <td>500</td>
                            </tr>
                            <tr>
                              <td>Брендированная футболка со&nbsp;стикерами (размер единый)</td>
                              <td>600</td>
                            </tr>
                            <tr>
                              <td>
                                Набор: Брендированная футболка со&nbsp;стикерами (размер единый) +
                                Набор чая Greenfield Natural Tisane в&nbsp;подарочной коробке
                              </td>
                              <td>1200</td>
                            </tr>
                            <tr>
                              <td>Плед</td>
                              <td>700</td>
                            </tr>
                            <tr>
                              <td>Набор: Свеча + Плед</td>
                              <td>1000</td>
                            </tr>
                            <tr>
                              <td>
                                Новая коллекция крупнолистового чая GREENFIELD&nbsp;GOURMET TEA
                                COLLECTION в&nbsp;подарочной коробке
                              </td>
                              <td>700</td>
                            </tr>
                            <tr>
                              <td>Набор: Кружка + Чай Greenfield</td>
                              <td>800</td>
                            </tr>
                            <tr>
                              <td>Набор чая Greenfield Natural Tisane в&nbsp;подарочной коробке</td>
                              <td>800</td>
                            </tr>
                            <tr>
                              <td>Брендированная футболка белая Harmony (размер единый)</td>
                              <td>900</td>
                            </tr>
                            <tr>
                              <td>
                                Набор: Шопер + Брендированная футболка белая Harmony (размер единый)
                              </td>
                              <td>1000</td>
                            </tr>
                            <tr>
                              <td>Брендированный свитшот (размер единый)</td>
                              <td>1000</td>
                            </tr>
                          </tbody>
                        </table>
                        <br />
                        <b>Ежемесячные призы:</b>
                        <br />
                        Сертификат М.Видео номиналом 10&nbsp;000 рублей&nbsp;&mdash; 11&nbsp;штук.
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      this.toggleFaq(15);
                    }}
                    className={'faq__item ' + (this.state.faq === 15 ? 'active' : '')}>
                    <div className="faq__item-header color-5">
                      Какое количество подарков можно получить за&nbsp;весь период программы
                      лояльности?
                    </div>
                    <div className={'faq__item-answer fadeIn animated'}>
                      <div className="faq__item-text">
                        Один участник может получить не&nbsp;более&nbsp;1 (одного) ежемесячного
                        приза и&nbsp;подарков на&nbsp;номинальную сумму, не&nbsp;превышающую
                        4&nbsp;000 (четыре тысячи) рублей за&nbsp;весь период акции.
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      this.toggleFaq(16);
                    }}
                    className={'faq__item ' + (this.state.faq === 16 ? 'active' : '')}>
                    <div className="faq__item-header color-5">Как получить подарок?</div>
                    <div className={'faq__item-answer fadeIn animated'}>
                      <div className="faq__item-text">
                        Подарок направляется организатором в&nbsp;течение всего срока выдачи
                        подарков почтой&nbsp;РФ за&nbsp;счет организатора в&nbsp;ближайшее почтовое
                        отделение по&nbsp;указанному почтовому адресу в&nbsp;личном кабинете
                        на&nbsp;сайте или в&nbsp;чат-боте.
                        <br />
                        Трек-номер для отслеживания будет присвоен после получения и&nbsp;обработки
                        заказа на&nbsp;подарок. Он&nbsp;появится в&nbsp;разделе &laquo;Мои
                        заказы&raquo; личного кабинета участника на&nbsp;сайте акции,
                        и&nbsp;участнику необходимо самостоятельно отслеживать обновление этого
                        трек-номера в&nbsp;личном кабинете
                        <br />
                        <br />
                        Электронные подарки будут доступны по&nbsp;кнопке &laquo;Забрать&raquo;
                        в&nbsp;личном кабинете в&nbsp;разделе &laquo;Мои заказы&raquo;.
                        <br />
                        <br />
                        Ежемесячный приз виде электронного сертификата М.Видео номиналом 10&nbsp;000
                        рублей доставляется победителю на&nbsp;адрес электронной почты участника,
                        указанной при регистрации на&nbsp;сайте акции.
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      this.toggleFaq(23);
                    }}
                    className={'faq__item ' + (this.state.faq === 23 ? 'active' : '')}>
                    <div className="faq__item-header color-5">
                      Как можно узнать, что стал победителем в&nbsp;розыгрыше ежемесячного приза?
                    </div>
                    <div className={'faq__item-answer fadeIn animated'}>
                      <div className="faq__item-text">
                        Каждому победителю отправляется поздравительное письмо на&nbsp;адрес
                        электронной почты участника, указанный при регистрации чека на&nbsp;сайте
                        акции.
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      this.toggleFaq(21);
                    }}
                    className={'faq__item ' + (this.state.faq === 21 ? 'active' : '')}>
                    <div className="faq__item-header color-5">
                      Через какое время отправят подарок?
                    </div>
                    <div className={'faq__item-answer fadeIn animated'}>
                      <div className="faq__item-text">
                        Подарки и&nbsp;призы направляются почтой&nbsp;РФ или на&nbsp;адрес
                        электронной почты в&nbsp;течение общего срока проведения акции.
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      this.toggleFaq(24);
                    }}
                    className={'faq__item ' + (this.state.faq === 24 ? 'active' : '')}>
                    <div className="faq__item-header color-5">
                      Что делать, если не&nbsp;успел забрать подарок из&nbsp;отделения Почты России?
                    </div>
                    <div className={'faq__item-answer fadeIn animated'}>
                      <div className="faq__item-text">
                        В&nbsp;случае, если участник своевременно не&nbsp;забрал подарок
                        в&nbsp;отделении почты России, повторная отправка подарка
                        не&nbsp;производится.
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      this.toggleFaq(17);
                    }}
                    className={'faq__item ' + (this.state.faq === 17 ? 'active' : '')}>
                    <div className="faq__item-header color-5">
                      Как изменить данные для доставки подарков?
                    </div>
                    <div className={'faq__item-answer fadeIn animated'}>
                      <div className="faq__item-text">
                        Обратиться в&nbsp;техническую поддержку через форму обратной связи
                        на&nbsp;сайте программы лояльности или в&nbsp;чат-боте.
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      this.toggleFaq(22);
                    }}
                    className={'faq__item ' + (this.state.faq === 22 ? 'active' : '')}>
                    <div className="faq__item-header color-5">
                      Как изменить подарок после заказа?
                    </div>
                    <div className={'faq__item-answer fadeIn animated'}>
                      <div className="faq__item-text">
                        После заказа подарка в&nbsp;личном кабинете, замена и&nbsp;возврат подарка
                        невозможен.
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      this.toggleFaq(18);
                    }}
                    className={'faq__item ' + (this.state.faq === 18 ? 'active' : '')}>
                    <div className="faq__item-header color-5">
                      Можно&nbsp;ли получить приз деньгами?
                    </div>
                    <div className={'faq__item-answer fadeIn animated'}>
                      <div className="faq__item-text">
                        Выдача призов в&nbsp;их&nbsp;денежном эквиваленте не&nbsp;производится,
                        равно как возврат и&nbsp;обмен.
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      this.toggleFaq(19);
                    }}
                    className={'faq__item ' + (this.state.faq === 19 ? 'active' : '')}>
                    <div className="faq__item-header color-5">
                      Кто является организатором программы лояльности?
                    </div>
                    <div className={'faq__item-answer fadeIn animated'}>
                      <div className="faq__item-text">
                        ООО &laquo;АРМ&raquo;, место нахождения: 115054, Москва, ул. Дубининская,
                        д.57, стр.2, пом.11, ОГРН 1047796532190, ИНН 7705609429, КПП 772501001
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      this.toggleFaq(20);
                    }}
                    className={'faq__item ' + (this.state.faq === 20 ? 'active' : '')}>
                    <div className="faq__item-header color-5">
                      Что делать, если возникли вопросы?
                    </div>
                    <div className={'faq__item-answer fadeIn animated'}>
                      <div className="faq__item-text">
                        Задайте нам свой вопрос через форму обратной связи на&nbsp;сайте программы
                        лояльности или в&nbsp;чат-боте. Мы&nbsp;обязательно Вам ответим
                        в&nbsp;течение 72&nbsp;часов!
                        <br />
                        Подробнее с&nbsp;правилами программы лояльности&nbsp;Вы можете ознакомиться,
                        скачав полную версию правил&nbsp;по
                        <a href={this.props.link.pravila + '.pdf'} target="_BLANK">
                          ссылке
                        </a>
                        .
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="section-content-right">
                <div className="box-content">
                  <h4>Не нашли ответ на свой вопрос?</h4>
                  <p>мы обязательно Вам ответим!</p>
                  <div className="form__list">
                    <div
                      className={
                        'form__item ' +
                        (this.state.data.feedback.nameFocus === true ||
                        this.state.data.feedback.name !== ''
                          ? 'form-focused'
                          : '')
                      }>
                      <div
                        className={[
                          'form__field',
                          this.state.errors.feedback.name === true ? 'has-error' : '',
                        ].join(' ')}>
                        <input
                          placeholder="имя"
                          ref={(name) => {
                            this.nameInput = name;
                          }}
                          type="text"
                          name="name"
                          autoComplete="off"
                          onFocus={() => {
                            let errors = this.state.errors;
                            let data = this.state.data;
                            data.feedback.nameFocus = true;
                            errors.feedback.name = false;
                            this.setState({ errors: errors, data: data });
                          }}
                          value={this.state.data.feedback.name}
                          onChange={(name) => {
                            let data = this.state.data;
                            data.feedback.name = name.target.value;
                            this.setState({ data: data });
                          }}
                          onBlur={() => {
                            let data = this.state.data;
                            data.feedback.nameFocus = false;
                            this.setState({ data: data });
                          }}
                        />
                        {this.renderError('feedback', 'name')}
                      </div>
                    </div>
                    <div
                      className={
                        'form__item ' +
                        (this.state.data.feedback.emailFocus === true ||
                        this.state.data.feedback.email !== ''
                          ? 'form-focused'
                          : '')
                      }>
                      <div
                        className={[
                          'form__field',
                          this.state.errors.feedback.email === true ? 'has-error' : '',
                        ].join(' ')}>
                        <input
                          placeholder="e-mail"
                          ref={(email) => {
                            this.emailInput = email;
                          }}
                          type="text"
                          name="email"
                          onFocus={() => {
                            let errors = this.state.errors;
                            let data = this.state.data;
                            data.feedback.emailFocus = true;
                            errors.feedback.email = false;
                            this.setState({ errors: errors, data: data });
                          }}
                          autoComplete="off"
                          value={this.state.data.feedback.email}
                          onChange={(email) => {
                            let data = this.state.data;
                            data.feedback.email = email.target.value;
                            localStorage.setItem('feedbackEmail', email.target.value);
                            this.setState({ data: data });
                          }}
                          onBlur={() => {
                            let data = this.state.data;
                            data.feedback.emailFocus = false;
                            this.setState({ data: data });
                          }}
                        />
                        {this.renderError('feedback', 'email')}
                      </div>
                    </div>
                    <div
                      className={
                        'form__item ' + (this.state.topicInput !== '' ? 'form-focused' : '')
                      }>
                      <div
                        className={[
                          'form__field form__select ',
                          this.state.errors.feedback.topic === true ? 'has-error' : '',
                        ].join(' ')}>
                        <div className="select__custom">
                          <div
                            onClick={() => this.toggleTopicDropdown()}
                            className="select__custom-input d-flex align-items-center">
                            <span>{this.state.topicInput}</span>
                            <div className="select__custom-arrow d-flex align-items-center justify-content-center">
                              <i
                                className={
                                  'select__arrow ' +
                                  (this.state.topicDropdown === true ? 'active' : '')
                                }></i>
                            </div>
                          </div>
                          <div
                            className={
                              'select__custom-dropdown ' +
                              (this.state.topicDropdown === true ? 'active' : '')
                            }>
                            <div className="select__custom-dropdown-list">
                              <div
                                onClick={() => this.selectTopic(1, 'Регистрация в акции')}
                                className={
                                  'select__custom-dropdown-item ' +
                                  (this.state.data.feedback.topic === 1 ? 'active' : '')
                                }>
                                Регистрация в акции
                              </div>
                              <div
                                onClick={() => this.selectTopic(2, 'Регистрация чека')}
                                className={
                                  'select__custom-dropdown-item ' +
                                  (this.state.data.feedback.topic === 2 ? 'active' : '')
                                }>
                                Регистрация чека
                              </div>
                              <div
                                onClick={() => this.selectTopic(3, 'Призовой фонд')}
                                className={
                                  'select__custom-dropdown-item ' +
                                  (this.state.data.feedback.topic === 3 ? 'active' : '')
                                }>
                                Призовой фонд
                              </div>
                              <div
                                onClick={() => this.selectTopic(4, 'Вопросы о продукте')}
                                className={
                                  'select__custom-dropdown-item ' +
                                  (this.state.data.feedback.topic === 4 ? 'active' : '')
                                }>
                                Вопросы о продукте
                              </div>
                              <div
                                onClick={() => this.selectTopic(5, 'Технические вопросы')}
                                className={
                                  'select__custom-dropdown-item ' +
                                  (this.state.data.feedback.topic === 5 ? 'active' : '')
                                }>
                                Технические вопросы
                              </div>
                              <div
                                onClick={() => this.selectTopic(6, 'Другое')}
                                className={
                                  'select__custom-dropdown-item ' +
                                  (this.state.data.feedback.topic === 6 ? 'active' : '')
                                }>
                                Другое
                              </div>
                            </div>
                          </div>
                        </div>

                        {this.renderError('feedback', 'topic')}
                      </div>
                    </div>
                    <div
                      className={
                        'form__item ' +
                        (this.state.data.feedback.messageFocus === true ||
                        this.state.data.feedback.message !== ''
                          ? 'form-focused'
                          : '')
                      }>
                      <div
                        className={[
                          'form__field',
                          this.state.errors.feedback.message === true ? 'has-error' : '',
                        ].join(' ')}>
                        <textarea
                          placeholder="Текст обращения"
                          ref={(mess) => {
                            this.messInput = mess;
                          }}
                          name="message"
                          autoComplete="off"
                          onFocus={() => {
                            let errors = this.state.errors;
                            let data = this.state.data;
                            data.feedback.messageFocus = true;
                            errors.feedback.message = false;
                            this.setState({ errors: errors, data: data });
                          }}
                          value={this.state.data.feedback.message}
                          onChange={(message) => {
                            let data = this.state.data;
                            data.feedback.message = message.target.value;
                            this.setState({ data: data });
                          }}
                          onBlur={() => {
                            let data = this.state.data;
                            data.feedback.messageFocus = false;
                            this.setState({ data: data });
                          }}
                        />
                        {this.renderError('feedback', 'message')}
                      </div>
                    </div>
                    <div
                      className={
                        'form__item ' +
                        (this.state.data.feedback.agree === true ||
                        this.state.data.feedback.agree !== ''
                          ? 'form-focused'
                          : '')
                      }>
                      <div
                        className={[
                          'checkbox__item form__field',
                          this.state.errors.feedback.agree === true ? 'has-error' : '',
                        ].join(' ')}>
                        <label>
                          <input
                            type="checkbox"
                            name="agree"
                            onChange={(agree) => {
                              let data = this.state.data;
                              let errors = this.state.errors;
                              data.feedback.agree = agree.target.checked;
                              errors.feedback.agree = false;
                              this.setState({ data: data });
                              this.setState({ errors: errors });
                            }}
                            checked={this.state.data.feedback.agree}
                          />
                          <div className="checkbox__decor"></div>
                          <div className="checkbox__title">
                            Я даю{' '}
                            <a href={this.props.link.agreement} target="_BLANK">
                              согласие
                            </a>{' '}
                            на обработку моих персональных данных
                          </div>
                        </label>
                        {this.renderError('feedback', 'agree')}
                      </div>
                    </div>
                    <div className="form__item form__item-actions d-flex justify-content-center">
                      <div
                        className={
                          'form__action d-flex justify-content-center ' +
                          (this.state.loading === true ? 'disabled' : '')
                        }>
                        <button
                          onClick={() => {
                            window.dataLayer.push({
                              event: 'gaEvent',
                              eventCategory: 'internal',
                              eventAction: 'click',
                              eventLabel: 'question',
                            });
                            this.submitFeedback();
                          }}
                          className="btn upload-check custom-main-button btn-1 btn-arrow-1">
                          задать вопрос
                          <div className="loader">
                            <img src={loader}></img>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
